export const SN_USER = "user"
export const USER_TOKEN_CHANGED = SN_USER + "/TOKEN_CHANGED"
export const USER_END_SESSION = SN_USER + "/END_SESSION"
export const USER_INFO_UPDATED = SN_USER + "/INFO_UPDATED"
export const USER_INSTANCE_URL_UPDATED = SN_USER + "/INSTANCE_URL_UPDATED"
export const USER_OMS_REDIRECTION_INFO_UPDATED = SN_USER + "/OMS_REDIRECTION_INFO_UPDATED"
export const USER_FACILITIES_UPDATED = SN_USER + "/FACILITIES_UPDATED"
export const USER_CURRENT_FACILITY_UPDATED = SN_USER + "/CURRENT_FACILITY_UPDATED"
export const USER_PERMISSIONS_UPDATED = SN_USER + '/PERMISSIONS_UPDATED'
export const USER_PRODUCT_STORES_UPDATED = SN_USER + '/PRODUCT_STORES_UPDATED'
export const USER_CURRENT_PRODUCT_STORE_UPDATED = SN_USER + '/CURRENT_PRODUCT_STORE_UPDATED'
export const USER_PRODUCT_STORE_SETTING_UPDATED = SN_USER + '/PRODUCT_STORE_SETTING_UPDATED'
export const USER_FIELD_MAPPINGS_UPDATED = SN_USER + '/FIELD_MAPPINGS_UPDATED'
export const USER_FIELD_MAPPING_CREATED = SN_USER + '/FIELD_MAPPING_CREATED'
export const USER_CURRENT_FIELD_MAPPING_UPDATED = SN_USER + '/_CURRENT_FIELD_MAPPING_UPDATED'